function activis_add_noopener(link) {
    let linkTypes = (link.getAttribute('rel') || '').split(' ');
    if (!linkTypes.includes('noopener')) {
      linkTypes.push('noopener');
    }
    link.setAttribute('rel', linkTypes.join(' ').trim());
  }

  function activis_add_new_tab_label(link) {
    if (!link.querySelector('.screen-reader-text')) {
      link.insertAdjacentHTML('beforeend', '<span class="screen-reader-text">'+auto_new_tab_options.label+'</span>');
    }
  }

  function activis_add_new_tab_icon(link) {
    if (!link.querySelector('.js-auto-new-tab-icon')) {
      link.insertAdjacentHTML('beforeend', '<svg class="icon icon-external-link" aria-label="'+auto_new_tab_options.label+'"><use xlink:href="#icon-external-link"></use></svg>');
    }
  }
  function activis_add_external_label(link) {
    if (!link.querySelector('.screen-reader-text')) {
      link.insertAdjacentHTML('beforeend', '<span class="screen-reader-text">'+auto_new_tab_options.label_external+'</span>');
    }
  }

  function activis_add_external_icon(link) {
    if (!link.querySelector('.js-auto-new-tab-icon')) {
      link.insertAdjacentHTML('beforeend', '<svg class="icon icon-external-link" aria-label="'+auto_new_tab_options.label_external+'"><use xlink:href="#icon-external-link"></use></svg>');
    }
  }

  const $admin_bar = document.getElementById('wpadminbar');
  if ($admin_bar) {
    $admin_bar.classList.add('auto-new-tab-icon--prevent');
  }
  document.querySelectorAll('a:not(.act-share__network, .auto-new-tab-icon--prevent)').forEach(link => {
    if (link.closest('.auto-new-tab-icon--prevent')) {
      return;
    }
    const is_absolute_href = link.href.indexOf('http') === 0 || link.href.indexOf('//') === 0;
    if (is_absolute_href && link.href.indexOf(window.location.host) === -1) {
      link.classList.add('js-auto-new-tab-link');
      activis_add_external_label(link);
      activis_add_external_icon(link);
    } 
  });

document.querySelectorAll('a[target="_blank"]:not(.act-share__network, .auto-new-tab-icon--prevent)').forEach(link => {
  if (link.closest('.auto-new-tab-icon--prevent')) {
    return;
  }
  activis_add_noopener(link);
  if (link.classList.contains('js-auto-new-tab-link')) {
    return;
  }

    link.classList.add('js-auto-new-tab-link');
    activis_add_new_tab_label(link);
    activis_add_new_tab_icon(link);
  });